<template>
  <a-modal
      v-model="showPopup"
      :width='500'
      title="添加内部备注"
      @cancel="handleCancel"
      @ok="handleConfirm"
  >
    <a-input
        allowClear
        v-model="params.content"
        size="small"
        type="textarea"
        :auto-size="{ minRows: 4, maxRows: 10 }"
        class="w-100-w"
        placeholder='内部备注，不对外展示'
    ></a-input>
  </a-modal>
</template>
<script>
import {postAddServiceNoteApi} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_apis"

export default {
  emits: ['success'],
  data() {
    return {
      showPopup: false,
      params: {
        orderId: undefined,
        content: undefined,
      }
    }
  },
  methods: {
    show(orderId) {
      this.params.orderId = orderId
      this.showPopup = true
    },
    /** 确定修改 */
    async handleConfirm() {
      if (!this.params.content) return this.$message.warning('内容不能为空')
      const res = await postAddServiceNoteApi(this.params)
      if (res.status !== '200') return
      this.$message.info('添加成功')
      this.handleCancel()
      this.$emit('success')
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        orderId: undefined,
        content: undefined,
      }
      this.showPopup = false
    }
  }
}
</script>