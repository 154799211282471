var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 500, title: "添加内部备注" },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c("a-input", {
        staticClass: "w-100-w",
        attrs: {
          allowClear: "",
          size: "small",
          type: "textarea",
          "auto-size": { minRows: 4, maxRows: 10 },
          placeholder: "内部备注，不对外展示"
        },
        model: {
          value: _vm.params.content,
          callback: function($$v) {
            _vm.$set(_vm.params, "content", $$v)
          },
          expression: "params.content"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }