import instance from "@/axios"

/** 获取订单列表数据API */
export const getOrderListApi = async (params) => {
    return instance({
        url: '/dq_admin/orderProduct/list',
        method: 'GET',
        params: params
    })
}
/** 获取订单列表数据API */
export const getOrderDetailInfoApi = async (params) => {
    return instance({
        url: '/dq_admin/orderProduct/getDetail',
        method: 'GET',
        params: params
    })
}

/** 延长收货 */
export const postExtendTakeOverProductApi = async (params) => {
    return instance({
        url: '/dq_admin/orderReturns/bidOrderDelay',
        method: 'GET',
        params: params
    })
}
/** 一键退款 */
export const postConfirmReturnPriceByOneKeyApi = async (params) => {
    return instance({
        url: '/dq_admin/orderReturns/bidOrderNotSendRefund',
        method: 'post',
        data: params
    })
}
/** 添加备注 */
export const postAddNotesApi = async (params) => {
    return instance({
        url: '/dq_admin/orderLog/add',
        method: 'post',
        data: params
    })
}

/** 取消评级转直发 */
export const postCancelRateServiceToSendBuyerApi = async (params) => {
    return instance({
        url: '/dq_admin/orderProduct/cancelRatingToDirectMode',
        method: 'get',
        params: params
    })
}
/** 退标版费 */
export const postReturnRateServiceVersionPriceApi = async (params) => {
    return instance({
        url: '/dq_admin/orderProduct/returnBiaobanMoney',
        method: 'get',
        params: params
    })
}
/** 修改标版费 */
export const postChangeRateServiceVersionPriceApi = async (params) => {
    return instance({
        url: '/dq_admin/orderProduct/addMarkingPlate',
        method: 'post',
        data: params
    })
}

/** 获取备注列表 */
export const getServiceNoteListApi = async (params) => {
    return instance({
        url: '/dq_admin/orderRemark/list',
        method: 'get',
        params: params
    })
}

/** 获取备注列表 */
export const postAddServiceNoteApi = async (params) => {
    return instance({
        url: '/dq_admin/orderRemark/add',
        method: 'post',
        data: params
    })
}

/** 发起退货退款申请获取最新买卖双方费率 */
export const getBuyerOrSellerServiceRateApi = async (params) => {
    return instance({
        url: '/dq_admin/orderReturns/calculateChangeRefundType',
        method: 'post',
        data: params
    })
}

/** 确定申请退货退款 */
export const postConfirmApplyReturnPriceOrReturnProductApi = async (params) => {
    return instance({
        url: '/dq_admin/orderReturns/platformOpenRefund',
        method: 'post',
        data: params
    })
}

/** 同步支付订单 */
export const postSyncUserPayOrderStatusApi = async (params) => {
    return instance({
        url: '/dq_admin/orderProduct/synchronizationPayStatus',
        method: 'get',
        params: params
    })
}

/** 同步线下支付订单 */
export const postSyncUserOfflinePayOrderStatusApi = async (params) => {
    return instance({
        url: '/dq_admin/orderProduct/offinePaySynchronizationPayStatus',
        method: 'get',
        params: params
    })
}

/** 获取用户线上下单快递信息数据 */
export const getUserOnLineCreateLogisticsInfoApi = async (logisticsNum) => {
    return instance({
        url: '/dq_admin/kdLogisticsDetail/getByKuaidiNum',
        method: 'get',
        params: {
            kuaidiNum: logisticsNum
        }
    })
}

/** 创建送评申请 */
export const postCreateRateServiceApplyApi = async (params) => {
    return instance({
        url: '/dq_admin/identificationResults/refundToRatingReason',
        method: 'get',
        params: params
    })
}

/** 获取替卖家，卖家选择评级操作接口 */
export const getForSellerOrBuyerSelectRateActionApi = async (params) => {
    return instance({
        url: '/dq_admin/recurRating/getReplaceUserSelectionDetail',
        method: 'get',
        params: params
    })
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve({
                message: '成功',
                status: '200',
                data: {
                    orderNo: 'xxxxxxxddsfasd',
                    titleText: '评级完成，等待买家选择',
                    contentText: '拍品鉴定为 <span style="color: red;">存疑</span>，经平台认定为卖家责任，若你选择需要，评级公司将会顺丰到付发送给你，此订单将不再支持售后。若你选择放弃交易，钱款原路退回给你，钱币退回给卖家，此订单完结。你可以在48小时内选择复评，超时未选择默认放弃复评。复评需要你自费承担费用，若复评结果与首次评级结果不一致，平台将依据复评结果来进行售后处理。你可以点击下方自费复评按钮查看详细规则说明和流程。',
                    logisticsPrice: 15,
                    rateResult: {
                        titleText: '首次评级结果',
                        huaxiaUrl: 'https://www.huaxiapj.com/querySendItem.html?orderNum=6473593681',
                        rateImgList: [
                            'https://image.douquan.com/test/product/6787917/2025-03-11/17416843233539939.jpg',
                            'https://image.douquan.com/test/product/6787917/2025-03-11/17416843233539939.jpg'
                        ],
                        rateCellList: [
                            {title: '名称', value: '光绪重宝宝泉当十'},
                            {title: '评级编号', value: '6473593681'},
                            {title: '评级分数', value: '华夏极美86'},
                            {title: '备注', value: '瑕疵'},
                        ]
                    },
                    addressList: [
                        {
                            addressId: 1,
                            provinceName: '浙江省',
                            cityName: '杭州市',
                            countyName: '西湖区',
                            detailInfo: '浙大科技园A座西区406浙大科技园A座西区406浙大科技园A座西区406',
                            userName: 'smm',
                            userPhone: '18736286542',
                        },
                        {
                            addressId: 2,
                            provinceName: '河南省',
                            cityName: '洛阳市',
                            countyName: '西湖区',
                            detailInfo: '浙大科技园A座西区406',
                            userName: 'smmxxx',
                            userPhone: '18736286542',
                        },{
                            addressId: 6,
                            provinceName: '河南省',
                            cityName: '洛阳市',
                            countyName: '西湖区',
                            detailInfo: '浙大科技园A座西区406',
                            userName: 'smmxxx',
                            userPhone: '18736286542',
                        },
                        {
                            addressId: 3,
                            provinceName: '江苏省',
                            cityName: '无锡市',
                            countyName: '西湖区',
                            detailInfo: '浙大科技园A座西区406',
                            userName: 'sxxxxxmm',
                            userPhone: '18736286542',
                        },
                        {
                            addressId: 4,
                            provinceName: '湖北省',
                            cityName: '武汉市',
                            countyName: '西湖区',
                            detailInfo: '浙大科技园A座西区406',
                            userName: 'smmxxxxx',
                            userPhone: '18736286542',
                        },
                    ],
                    buttonList: [
                        {
                            btnText: '需要，暂缓发货',
                            btnStyleType: 'default',
                            btnType: 'needNotSendProduct',
                            tipMessage: '提示是否替用户选择：【需要，暂缓发货】？',
                            apiUrl: '',
                        },
                        {
                            btnText: '需要直接发货',
                            btnStyleType: 'primary',
                            btnType: 'needAndSendProduct',
                            tipMessage: '',
                            apiUrl: '',
                        },
                        {
                            btnText: '不要了',
                            btnStyleType: 'danger',
                            btnType: 'notNeed',
                            tipMessage: '提示是否替用户选择：【不要了】？',
                            apiUrl: '',
                        },
                    ]
                }
            })
        }, 400)
    })
}

/** 确定提交替买家，卖家选择数据 */
export const postConfirmForSellerOrBuyerSelectActionApi = async (params, apiUrl) => {
    return instance({
        url: apiUrl,
        method: 'post',
        data: params
    })
}

/** 确定提交替买家，卖家选择数据 */
export const postConfirmBtnNeedAndSendProductApi = async (params, apiUrl) => {
    return instance({
        url: apiUrl,
        method: 'post',
        data: params
    })
}

/** 获取物流预估邮费 */
export const getForecastPostagePriceApi = async (addressId) => {
    // return instance({
    //     url: '',
    //     method: 'get',
    //     params: {
    //         addressId: addressId,
    //     }
    // })
    return new Promise((resolve, reject) => {
        resolve({
            status: '200',
            message: '成功',
            data: {
                postagePrice: 0
            }
        })
    })
}