var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order-log-list-info-pages" },
    _vm._l(_vm.orderNoteList, function(item, index) {
      return _c("div", { key: index, staticClass: "order-log-item" }, [
        _c("div", { staticClass: "order-item-left" }, [
          _vm._v(_vm._s(index + 1))
        ]),
        _c("div", { staticClass: "order-item-right" }, [
          _c("div", { staticClass: "font-weight-bold color-gray" }, [
            _vm._v(_vm._s(item.createTime))
          ]),
          _c("div", { staticClass: "font-weight-bold color-black mt-5" }, [
            _vm._v(_vm._s(item.sysAccountName) + "【"),
            _c("span", { staticClass: "color-blue" }, [
              _vm._v(_vm._s(item.sysAccountId))
            ]),
            _vm._v("】")
          ]),
          _c("div", { staticClass: "mt-5" }, [
            _c("span", [_vm._v("备注内容：")]),
            _c("span", { staticClass: "font-weight-bold color-black" }, [
              _vm._v(_vm._s(item.content))
            ])
          ])
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }